import request from '@/utils/request'
export const uploadImageApi = (data) => request.post('/upload/uploadImage', data)

export function uploadFileApi(data){
  return request({
    url:'/upload/uploadFile',
    method:'post',
    data,
    useToken:true
  })
}
