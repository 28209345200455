<template>
  <button style="
  margin-right: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #D46A8E;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  border: none;
  padding: 0px;
  margin: 0px;
  "
  @click="attention('attention')"
  :disabled="loadingObj.attention"
  >
  <LoadingButton v-if="loadingObj.attention" style="margin-top: 5px;" :size="20"></LoadingButton>
    <span v-if="!loadingObj.attention" style="font-size: 13px;">
    {{isAttention?'取消关注':'关注'}}
    </span>
  </button>
</template>

<script>
  import { isAttentionApi,attentionUserApi,cancelAttentionApi} from '@/api/relative'
  export default{
    name:'Attention',
    props:['attentionedUser','attentionUser','attentionedNickname'],
    created(){
      this.isAttentionFun()
    },
    data() {
      return {
        isAttention:false,
        loadingObj:{}
      }
    },
    methods: {
      async attention(loading) {
        this.$set(this.loadingObj,loading,true)
        if(this.isAttention){
          this.cancelAttention(loading)
        }else{
          this.toAttention(loading)
        }
      },
      async toAttention(loading){
        await attentionUserApi(this.attentionedUser).then((res)=>{
          if(res.data.success){
            this.$message({
              type:'success',
              message:'您关注了'+this.attentionedNickname
            })
            this.isAttention=true
          }else{
            this.$message({
              type:'warning',
              message:res.data.errorMsg
            })
          }
          this.$set(this.loadingObj,loading,false)
        })
      },
      async cancelAttention(loading){
        await cancelAttentionApi(this.attentionedUser).then((res)=>{
          if(res.data.success){
            this.$message({
              type:'success',
              message:'您取消了对'+this.attentionedNickname+'的关注'
            })
            this.isAttention=false
          }else{
            this.$message({
              type:'warning',
              message:res.data.errorMsg
            })
          }
          this.$set( this.loadingObj , loading , false)

        })
      },
      async isAttentionFun(){
        isAttentionApi(this.attentionedUser,this.attentionUser).then((res)=>{
          if(res.data.success){
            this.isAttention=res.data.data
          }
        })
      }
    },
  }
</script>
<style>
</style>
