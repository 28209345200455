<template>
  <div class="userperson" style="margin: auto;"
  v-infinite-scroll="loadChild"
  :infinite-scroll-disabled="disabled"
  :infinite-scroll-immediate="true"
  :infinite-scroll-distance="500"
  >
    <el-container v-if="user&&user!=null">
       <el-header style="height: 100%;color:#F3F4F6;padding: 0px;">
         <el-row class="background_img"
         :style="{background:'url('+user.backgroundWall+') no-repeat',
         backgroundPosition: user.backgroundWallX+'px '+user.backgroundWallY+'px',
         backgroundAttachment: 'fixed',
         backgroundSize: 'cover',
         }
         "
         >
           <div class="background_img_mask"
           :style="{
             backgroundColor: 'rgba(0,0,0,'+user.backgroundWallMaskRgba/100+')'
           }"
           ></div>
           <el-col :md="{span:8,offset:8}" :xs="{span:8,offset:8}" style="text-align: center;">
             <el-avatar size="large" :src="user.avatar"
               style="width: 80px;height:80px;"
             >
             </el-avatar>
              <h3 style="">{{user.nickname}}</h3>
             </el-col>
             <el-col :md="24">
             <el-row style="margin-bottom: 10px;font-size: 13px;height: 30px;line-height: 30px;">
               <el-col :md="{span:2,offset:8}" :xs="5">
                 获赞{{user.praise}}
               </el-col>
               <el-col :md="{span:2}":xs="5">
                 获踩{{user.step}}
               </el-col>
               <el-col :md="{span:2}":xs="5">
                 文章{{user.blogNum}}
               </el-col>
               <el-col :md="{span:2}":xs="5">
                 浏览{{user.glance}}
               </el-col>
               <!-- 粉丝关注模块尚未完善 -->
               <el-col :md="{span:2}":xs="4">
                 粉丝{{user.fans}}
               </el-col>
               <el-col :md="{span:2,offset:4}"
               v-if="username!=user.username"
               >
                 <Attention style="
                 position: relative;
                 width: 100px;
                 "

                 :attentionedUser="user.username"
                 :attentionUser="username"
                 :attentionedNickname="user.nickname"
                 ></Attention>
               </el-col>
               <el-col :md="{span:2,offset:4}"
               v-if="username==user.username"
               class="changeBackgroundWall"

               >
               <el-popover
                  placement="bottom-start"
                  width="800"
                  trigger="hover"
                  v-if="username==user.username"
                  >
                  <span slot="reference">更换背景墙</span>
                  <div>
                    <input type="file"
                    style="display: none;"
                    ref="inputTypeFile"
                    @change="inputTypeFileChange"
                    />
                      <span
                      class="changeBackgroundWall"
                      style="cursor: pointer;width: 200px;height: 50px;
                      display: inline-block;
                      line-height: 50px;
                      "
                      @click.stop="checkFile"
                      v-if="changeBackgroundWallType"
                      >上传背景墙</span>
                      <el-input v-if="!changeBackgroundWallType" v-model="backgroundWallUrl"
                      placeholder="请输入图片链接"
                      style="width: 500px;height: 50px;"
                      @change="inputTypeTextUrlChange"
                      ></el-input>
                         <el-switch
                           style="display: block"
                           v-model="changeBackgroundWallType"
                           active-color="#13ce66"
                           inactive-color="#409EFF"
                           active-text="本地上传"
                           inactive-text="链接方式">
                         </el-switch>
                         <div class="block" style="width: 800px;">
                           <span class="demonstration"
                           >X</span>
                             <el-slider v-model="user.backgroundWallX"
                             :min="-1123"
                             :max="1123"
                             show-input
                             ></el-slider>

                         </div>
                         <div class="block" style="width: 800px;">
                              <span class="demonstration"
                              style=""
                              >Y</span>
                             <el-slider v-model="user.backgroundWallY"
                             :min="-750"
                             :max="750"
                             show-input
                             ></el-slider>

                         </div>
                         <div class="block" style="width: 800px;">
                           <span class="demonstration"
                           >蒙版透明度</span>
                             <el-slider v-model="user.backgroundWallMaskRgba"
                             :min="0"
                             :max="100"
                             show-input
                             >
                             </el-slider>

                         </div>
                         <el-button
                          style="cursor: pointer;"
                         @click.stop="changeBackgroundWall('changeBackgroundWallLoading')"
                         :loading="loadingObj['changeBackgroundWallLoading']"
                         >保存</el-button>
                  </div>
                </el-popover>
               </el-col>

             </el-row>
            </el-col>
         </el-row>
       </el-header>
           <el-row class="menu_router_link" style="text-align: center;">
             <el-col :md="{span:username==currUsername?5:10}" :xs="0">
               <span style="visibility:hidden">用来撑起来的控件</span>
             </el-col>
             <el-col :md="{span:2}" v-for="(item,i) in mainPath" :xs="8" style="height: 100%;">
               <template >
                <router-link
                v-if="item.show===true"
                :to="{path:item.path,query:{username:$route.query.username}}"
                :class="$route.path!=item.path?'menu_router_link_a':'menu_router_link_a_selected'"
                :key="i"
                >
                  <span v-if="item.path=='/userBlog'">
                  {{username==currUsername?'我的文章':'他的文章'}}
                  </span>
                  <span v-if="item.path=='/userComment'">
                    {{username==currUsername?'我的评论':'他的评论'}}
                  </span>
<!--                  <span v-if="item.path=='/myAttention'">
                    {{username==currUsername?'我的关注':'他的关注'}}
                  </span> -->
                  <span v-if="item.path!='/userBlog'&& item.path!='/userComment'">
                    {{item.name}}
                  </span>
                </router-link>
                </template>
                <el-badge v-if="item.path=='/message'&&username==currUsername&&messageTotal&&messageTotal>0"
                :value="messageTotal" class="mark" :max="99"
                style="margin-left: -10px;position: absolute;z-index:99999"
                />
                </span>
             </el-col>
           </el-row>
           <el-main style="background-color: #ffffff;padding:0px">
             <router-view ref="childBlog"
              @changeDisabled="computedDisabled"
              @restUser="restUser"
             >
             </router-view>
           </el-main>
        </el-container>
        <el-dialog :visible.sync="showBackgroundWall">
          <img width="100%" :src="showBackgroundUrl" alt="">
        </el-dialog>
  </div>
</template>
<script>
import global from '@/api/global_variable.js'
import {getUserApi,changeBackgroundWallApi} from '@/api/user'
import { mapGetters } from 'vuex'
import Attention from './components/Attention'
import {uploadFileApi} from '@/api/upload'
export default {
  components: {
    Attention
  },
  computed: {
    ...mapGetters([
      'messageTotal',
      'username',
      'nickname'
    ])
  },
  created() {
    const username=this.$route.query.username
    if(username!=undefined){
      this.getUser(username)
    }
  },
  watch: {
    $route(to, from) {
      this.getUser(to.query.username)
    }
  },
  data(){
      return{
        changeBackgroundWallType:true,
        showBackgroundUrl:'',
        showBackgroundWall:false,
        backgroundWallVisible:false,
        loadingObj:{},
        loading:false,
        avatarBaseUrl:global.baseURL,
        backgroundWallUrl:'',
        imgURL:process.env.VUE_APP_BASE_IMAGES_URL,
        user:{
          },
        currentMenu:this.$route.path,
        currUsername:this.$route.query.username,
        mainPath:[
          {
            name:'创作',
            path:'/publish',
            show:false
          },
          {
            name:'我的文章',
            path:'/userBlog',
            show:true
          },
          {
            name:'草稿箱',
            path:'/drafts',
            show:false
          },{
            name:'资料',
            path:'/material',
            show:false
          },{
            name:'消息',
            path:'/message',
            show:false
          },{
            name:'我的关注',
            path:'/myAttention',
            show:false
          },{
            name:'我的评论',
            path:'/userComment',
            show:false
          }
        ],
        disabled:false
      }
  },
  methods:{
    formatTooltip(val) {
      return val / 100;
    },
    inputTypeFileChange(){
      this.user.backgroundWall=window.URL.createObjectURL(this.$refs.inputTypeFile.files[0])
    },
    inputTypeTextUrlChange(){
      this.user.backgroundWall=this.backgroundWallUrl
    },
    checkFile(){
      this.$refs.inputTypeFile.dispatchEvent(new MouseEvent('click'));
    },
    async changeBackgroundWall(loading){
      this.$set(this.loadingObj,loading,true)
      let data={}
      if(this.changeBackgroundWallType){
        let form=new FormData()
        form.append('file',this.$refs.inputTypeFile.files[0])
        if(this.$refs.inputTypeFile.files[0]){
          await uploadFileApi(form).then((res)=>{
            if(res.data.success){
              data.backgroundWall=res.data.data
            }
          })
        }
      }else{
        data.backgroundWall=this.backgroundWallUrl
      }
      data.backgroundWallMaskRgba=this.user.backgroundWallMaskRgba
      data.backgroundWallX=this.user.backgroundWallX
      data.backgroundWallY=this.user.backgroundWallY
      await changeBackgroundWallApi(data).then((res2)=>{
        if(res2.data.success){
          this.$message({
            type:'success',
            message:'保存成功'
          })
        }else{
          this.$message({
            type:'warning',
            message:res2.data.errorMsg
          })
        }
      })
      this.$set(this.loadingObj,loading,false)
    },
    loadChild(){
      if(this.currentMenu=='/userBlog'
      || this.currentMenu=='/drafts'
      || this.currentMenu=='/message'
      || this.currentMenu=='/userComment'
      || this.currentMenu=='/myAttention'
      ){
        if(this.$refs.childBlog){
          this.$refs.childBlog.load()
        }
      }
    },
    computedDisabled(val){
      this.disabled=val
    },
    restUser(val){
      this.getUser(val)
    },
    async getUser(username){
      this.loading=true
      let res=await getUserApi({username:username})
      this.currUsername=username
      if(res.data.success){
        this.loading=false
        this.user=res.data.data.userInfo
        if(this.user.backgroundWall==null){
            this.user.backgroundWall='https://img3.wallspic.com/previews/3/9/7/1/7/171793/171793-jiu_jin_shan-jian_zhu-chuang_kou-ju_xing-ta_kuai-x750.jpg'
        }
        if(this.user.backgroundWallX==null){
          this.user.backgroundWallX=0
        }
        if(this.user.backgroundWallY==null){
          this.user.backgroundWallY=-350
        }
        if(this.user.backgroundWallMaskRgba==null){
          this.user.backgroundWallMaskRgba=30
        }
          if(this.currUsername!=undefined && this.currUsername===this.username){
            this.mainPath[0].show=true
            this.mainPath[2].show=true
            this.mainPath[3].show=true
            this.mainPath[4].show=true
            this.mainPath[5].show=true
            this.mainPath[6].show=true
          }else{
            this.mainPath[0].show=false
            this.mainPath[1].show=true
            this.mainPath[2].show=false
            this.mainPath[3].show=false
            this.mainPath[4].show=false
            this.mainPath[5].show=false
            this.mainPath[6].show=true
          }
        }
    }
  }
}

</script>
<style scoped>
  .changeBackgroundWall{
    cursor: pointer;
    transition: all 0.2s;
  }
  .changeBackgroundWall:hover{
    color: #D9D596;
  }
  .background_img{
    width: 100%;
    border:1px solid transparent;
    /* background:url('https://img3.wallspic.com/previews/3/9/7/1/7/171793/171793-jiu_jin_shan-jian_zhu-chuang_kou-ju_xing-ta_kuai-x750.jpg') no-repeat; */
    padding-top:130px;
    z-index: 2;
  }
  .background_img_mask{
    width:100%;
    height: 100%;
    position: absolute;

    margin-top:-130px;
    z-index: -1;
  }
.menu_router_link{
  background-color: #ffffff;
  border-bottom: 1px solid #C4C8D0;
  text-align: center;
}
.menu_router_link_a{
  text-decoration: none;
  color: #757575;
  font-size: 14px;
  display: inline-block;
  height: 100%;
  line-height: 50px;
  width: 100px;
  transition-property: all;
  transition-duration: 0.5s;
}
.menu_router_link_a_selected{
  text-decoration: none;
  color: #443B60;
  font-size: 14px;
  display: inline-block;
  height: 100%;
  line-height: 50px;
  width: 100px;
  border-bottom: 2px solid #443B60;
  transition-property: all;
  transition-duration: 0.5s;
}
.menu_router_link_a:hover{
  background-color: #DCDFE6;
}
</style>
